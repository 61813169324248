<template>
  <nav>
    <router-link to="/">主页</router-link>
    <router-link v-if="!isAuthenticated" to="/login">登录</router-link>
    <router-link v-if="!isAuthenticated" to="/register">注册</router-link>
    <router-link
      :to="isAuthenticated ? '/cloud-storage' : '#'"
      :class="['cloud-storage-link', { 'disabled': !isAuthenticated }]"
      @click="!isAuthenticated && preventNavigation">
      云端储存
    </router-link>
    <button v-if="isAuthenticated" @click="logout">退出账号</button>
  </nav>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'App',
  computed: {
    ...mapGetters(['isAuthenticated', 'isLoading'])
  },
  methods: {
    ...mapActions(['checkAuthentication']),
    async logout() {
      await this.$store.dispatch('logout')
      this.$router.push('/login')
    }
  },
  async created() {
    await this.checkAuthentication()
  },
  watch: {
    '$route': {
      handler: async function() {
        if (this.$route.name !== 'login') {
          await this.checkAuthentication()
        }
      },
      immediate: true
    }
  }
}
</script>

<style scoped>
nav {
  color: #ff8800;
  margin-top: 10px;
}

nav a {
  color: #F0F0F0;
  text-decoration: none;
  margin-right: 10px;
}

.cloud-storage-link {
  color: #42b983;
  cursor: pointer;
}

.disabled {
  color: rgb(128, 128, 128);
  cursor: not-allowed;
}

button {
  background-color: #42b983;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
}

button:hover {
  background-color: #3aa876;
}
</style>
