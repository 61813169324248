<template>
  <div class="register">
    <h2>注册</h2>
    <form @submit.prevent="register">
      <input v-model="name" type="name" placeholder="姓名" required>
      <input v-model="username" type="text" placeholder="用户名" required>
      <input v-model="password" type="password" placeholder="密码" required>
      <input v-model="email" type="email" placeholder="邮箱" required>
      <button type="submit">注册</button>
    </form>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      name: '',
      username: '',
      password: ''
    }
  },
  methods: {
    async register() {
    try {
      let config = {
                    headers: {
                      Authorization: 'Basic bXljbGllbnQ6c2VjcmV0'
                    }
                }
        const response = await axios.post('https://www.skmao123.com/api/v1/users/register', {
            name: this.name,
            username: this.username,
            password: this.password,
            email: this.email
        },config)
        alert("账号创建成功"); // Show success message
        console.log(response);
        this.$router.push('/login'); // Redirect to login page
    } catch (error) {
        console.error("账号名称已被占用！", error);
        alert('Registration failed. Please try again.');
    }
  }
  }
}
</script>