import { createRouter, createWebHistory } from 'vue-router'
   import HomePage from './components/HomePage.vue'
   import LoginPage from './components/LoginPage.vue'
   import RegisterPage from './components/RegisterPage.vue'
   import CloudStorage from './components/CloudStorage.vue'
   import store from './store'

   const routes = [
     { path: '/', component: HomePage },
     { path: '/login', component: LoginPage },
     { path: '/register', component: RegisterPage },
      { 
        path: '/cloud-storage',
        component: CloudStorage,
        meta: { requiresAuth: true }
      }
      // { 
      //   path: '/another-restricted',
      //   component: () => import('./components/AnotherRestrictedPage.vue'),
      //   meta: { requiresAuth: true }
      // }
]

   const router = createRouter({
     history: createWebHistory(process.env.BASE_URL),
     routes
   })
   // Navigation guard
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // Check if the user is logged in
    if (!store.getters.isAuthenticated) {
      next('/login') // Redirect to login if not authenticated
    } else {
      next() // Proceed to the route
    }
  } else {
    next() // Proceed to the route
  }
})


   
export default router