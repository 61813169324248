<template>
  <title>编程猫</title>
  <head><link rel="icon" href="src/assets/favicon.ico" type="image/x-icon"></head>
  <div id="app">
    <header>
      <h1>科尔的编程猫</h1>
      <NavBar/>
    </header>
    <main>
      <router-view/>
      <button v-if="!scratchStarted" @click="startScratch" class="start-button">
        启动Scratch
      </button>
      <!-- Only show ScratchEditor after the Start button is clicked -->
      <scratch-editor v-if="scratchStarted"></scratch-editor>

      <div>
        <div  style="padding-bottom: 50px">
          <div style="margin-top: 10px">信息</div>
          <div>Made by<span style="margin-left: 10px">Cole</span></div>
          <div style="margin-top: 10px;color: #000">
            <span>关于</span>
            <span style="margin-left: 10px;border-left: 1px solid #A4A3AB;padding-left: 10px">招贤纳士</span>
            <span style="margin-left: 10px;border-left: 1px solid #A4A3AB;padding-left: 10px">商务合作</span>
            <span style="margin-left: 10px;border-left: 1px solid #A4A3AB;padding-left: 10px">寻求报道</span>
          </div>
          <div style="margin-top: 10px">©2024 科尔编程猫</div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
// import UserList from './components/UserList.vue';
import ScratchEditor from './components/ScratchEditor.vue';
import NavBar from './components/NavBar.vue';

export default {
  name: 'App',
  components: {
    // UserList,
    ScratchEditor,
    NavBar
  },
  data() {
    return {
      scratchStarted: false // Tracks if Scratch has been started
    };
  },
  methods: {
    startScratch() {
      this.scratchStarted = true; // Set this to true when the Start button is clicked
    }
  }
};
</script>

<style>
#app {
  font-family: Arial, sans-serif;
  max-width: 1080px;
  margin: 0 auto;
  padding: 20px;
}

header {
  background: linear-gradient(135deg, #8A2BE2, #FF7F00); /* Gradient from orange to purple */
  padding: 20px;
  margin-bottom: 20px;
}


h1 {
  margin: 0;
  font-size: 2rem;
  color: #FFEB3B;
  /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); /* Text shadow for depth */
}

nav {
  margin-top: 10px;
}

nav a {
  color: white;
  text-decoration: none;
  margin-right: 10px;
  font-weight: bold;
  transition: color 0.3s ease;
}

nav a:hover {
  color: #FFD700; /* Gold color on hover */
}

.login, .register {
  max-width: 300px;
  margin: 0 auto;
}

form {
  display: flex;
  flex-direction: column;
}

input {
  margin-bottom: 10px;
  padding: 5px;
}

button {
  background-color: #42b983;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
}

button:hover {
  background-color: #3aa876;
}
</style>
