<!-- src/components/ScratchEditor.vue -->
<template>
    <div class="scratch-editor-container">
        <iframe
            :src="scratchEditorUrl"
            class="scratch-editor"
            frameborder="0"
            allowfullscreen
        ></iframe>
    </div>
</template>

<script>
export default {
    name: "ScratchEditor",
    data() {
        return {
            scratchEditorUrl: "https://scratch.skmao123.com" // URL where the Scratch GUI is running
        };
    }
};
</script>

<style scoped>
.scratch-editor-container {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    height: 100vh; /* Full height of the viewport */
    /* padding: 10px; /* Optional padding */
    box-sizing: border-box; /* Ensure padding is included in height calculation */
}
.scratch-editor {
    flex: 1;
    width: 100%;
    height: 100%;
    max-width: 1200px; /* Example max width */
    max-height: 800px;  /* Example max height */
    margin: 0 auto;    /* Center the iframe horizontally */
}

</style>
