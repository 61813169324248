import {createStore} from 'vuex'
import axios from 'axios';

// Set up axios interceptor
axios.interceptors.request.use(config => {
    const token = localStorage.getItem('token');
    if (token) {
        if (config.headers.Authorization && config.headers.Authorization.startsWith('Basic')) return config
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, error => {
    return Promise.reject(error);
});

export default createStore({
    state: {
        user: null,
        token: localStorage.getItem('token'),
        loading: false
    },
    mutations: {
        SET_USER(state, user) {
            state.user = user
        },
        SET_TOKEN(state, token) {
            state.token = token
            if (token) {
                localStorage.setItem('token', token)
            } else {
                localStorage.removeItem('token')
            }
        },
        CLEAR_USER(state) {
            state.user = null
            state.token = null
            localStorage.removeItem('token')
        },
        SET_LOADING(state, isLoading) {
            state.loading = isLoading
        }
    },
    actions: {
        async login({commit, dispatch}, {username, password}) {
            try {
                commit('SET_LOADING', true);
                let params = new URLSearchParams();
                params.append('username', username);
                params.append('password', password);
                params.append('grant_type', 'password');
                let config = {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Basic bXljbGllbnQ6c2VjcmV0'
                    }
                }
                const response = await axios.post('https://www.skmao123.com/api/oauth/token', params, config);
                if (response.data.code == 500 || response==null) {
                    return false;
                }
                const token = response.data.access_token;
                console.log(token)
                console.log(response)
                commit('SET_TOKEN', token);
                await dispatch('fetchUser');
                return true;
            } catch (error) {
                console.error("Login failed", error);
                return false;
            } finally {
                commit('SET_LOADING', false);
            }
        },
        async logout({commit}) {
            commit('CLEAR_USER');
        }
    },
    getters: {
        isAuthenticated: state => !!state.token,
        isLoading: state => state.loading
    }
})
