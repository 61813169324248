<template>
  <div class="login">
    <h2>登录</h2>
    <form @submit.prevent="login">
      <input v-model="username" type="text" placeholder="用户名" required>
      <input v-model="password" type="password" placeholder="密码" required>
      <button type="submit" :disabled="$store.state.loading">登录</button>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: '',
      password: ''
    }
  },
  methods: {
    async login() {
      const success = await this.$store.dispatch('login', {
        username: this.username,
        password: this.password
      });
      if (success) {
        this.$router.push('/');
        alert("登录成功")
      } else {
        alert('登录失败，请检查您的登录信息');
      }
    }
  }
}
</script>

