<!-- src/components/CloudStorage.vue -->
<template>
  <div class="cloud-storage">
    <h2>云端储存</h2>
    <p>欢迎来到云端储存！你可以在这里安全地储存你的Scratch工程:)</p>

    <!-- File upload section -->
    <div class="upload-section">
      <input type="file" @change="uploadFile"/>
      <button @click="upload">上传</button>
    </div>

    <!-- Display uploaded files -->
    <div v-if="files.length" class="files-list">
      <h3>您的Scratch文件</h3>
      <ul>
        <li v-for="(file, index) in files" :key="index">
          {{ file.name }} - <a :href="'https://www.skmao123.com/api/v1/down/'+file.name" target="_blank">下载</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      files: [], // Array to store uploaded files
      selectedFile: null // File selected for upload
    }
  },
  methods: {
    uploadFile(event) {
      this.selectedFile = event.target.files[0]; // Capture the selected file
    },
    upload() {
      if (!this.selectedFile) {
        alert('请选择您想要上传的文件');
        return;
      }

      // 创建axios实例
      var instance = axios.create({
        baseURL: '/', // 服务器地址
        timeout: 1000, // 请求超时时间
      });

      // 设置请求头
      instance.defaults.headers.post['Content-Type'] = 'multipart/form-data';

      // 创建formData对象
      var formData = new FormData();
      formData.append('file', this.selectedFile);

      const token = localStorage.getItem('token');

      // 发送文件上传请求
      instance.post('/api/v1/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`
        }
      }).then(function () {
        // 文件上传成功
        alert('文件上传成功');
      }).catch(function () {
        // 文件上传失败
        alert('文件上传失败');
      });

      // Replace with your actual upload logic
      const uploadedFile = {
        name: this.selectedFile.name,
        url: 'https://www.skmao123.com/api/v1/upload' + this.selectedFile.name // Mock URL for the uploaded file
      };

      // Add the file to the files array
      this.files.push(uploadedFile);

      // Clear the selected file
      this.selectedFile = null;
    }
  }
}
</script>

<style scoped>
.cloud-storage {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.upload-section {
  margin-bottom: 20px;
}

.files-list {
  margin-top: 20px;
}

button {
  background-color: #42b983;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
}

button:hover {
  background-color: #3aa876;
}
</style>
