// src/main.js
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store' // Import the Vuex store
import axios from 'axios';

// Function to generate a CSRF token (simple implementation)
function generateCsrfToken() {
    const token = Math.random().toString(36).substring(2); // Simple token generation
    document.cookie = `XSRF-TOKEN=${token}; path=/; SameSite=None; Secure`; // Store the token in a cookie
    return token;
}

// Initialize CSRF token when the application starts
if (!document.cookie.includes('XSRF-TOKEN')) {
    generateCsrfToken();
}

// Set up an axios interceptor to include the CSRF token in all requests
axios.interceptors.request.use(config => {
    const csrfToken = document.cookie.split('; ').find(row => row.startsWith('XSRF-TOKEN')).split('=')[1];
    config.headers['X-XSRF-TOKEN'] = csrfToken;
    return config;
}, error => {
    return Promise.reject(error);
});

const app = createApp(App)
app.use(router)
app.use(store) // Use the store in your Vue app

app.mount('#app')